





































































import { Component, Ref, Vue } from 'vue-property-decorator';
import VueUeditorWrap from 'vue-ueditor-wrap';
import UploadFile from '@c/upload/UploadFile.vue';
import { InstitutionArticleAdd, InstitutionArticleDetail, InstitutionArticleUp } from '@/http/api/InstitutionApi';

interface Iarticle {
  title?: string | any[];
  source?: string | any[];
  link?: number | any[];
  url?: string | any[];
  abstract?: string | any[];
  content?: any | any[];
  attFiles?: any | any[];
}
@Component({
  components: {
    VueUeditorWrap,
    UploadFile,
  },
})
export default class MaterialAdd extends Vue {
  @Ref() private articleFrom!: HTMLDListElement;
  private id: string = '';
  private loading: boolean = false;
  private pageLoading: boolean = false;
  private articleClass: any = 'consult';
  private articleInfo: Iarticle = {
    title: '',
    source: '',
    link: 1,
    url: '',
    abstract: '',
    content: '',
    attFiles: '',
  };
  private articleRules: Iarticle = {
    title: [
      { required: true, message: '请输入标题', trigger: 'blur' },
    ],
  };
  private uploadFileConfig: any = {
    limit: 1,
    callback: [],
  };
  private ueditorConfig: any = {
    UEDITOR_HOME_URL: '/web/ueditor/',
    initialFrameHeight: 400,
  };
  private editorInstance: any = '';
  private article: any = {
    content: '',
  };
  private created() {
    this.articleClass = this.$route.query.article;
    if (this.$route.query.id) {
      this.id = (this.$route.query.id as string);
      this.getArticle(this.$route.query.id);
    }
  }
  // 获取文章详情
  private getArticle<T>(id: T) {
    this.pageLoading = true;
    InstitutionArticleDetail({ id }).then((res) => {
      this.pageLoading = false;
      if (res.code === '000') {
        this.articleInfo.title = res.result.title;
        this.articleInfo.source = res.result.source;
        if (res.result.url) {
          this.articleInfo.link = 2;
        } else {
          this.articleInfo.link = 1;
        }
        this.articleInfo.url = res.result.url;

        this.articleInfo.abstract = res.result.contentPure;
        this.articleInfo.content = res.result.content;
        this.articleInfo.attFiles = res.result.attFiles;
        if (res.result.attFiles) {
          this.uploadFileConfig.callback = res.result.attFileList;
        }
      }
    });
  }
  // 监听文章类型
  private radioChange(e: number) {
    if (e === 1) {
      delete this.articleRules.url;
      this.articleInfo.url = '';
    } else {
      this.articleRules.url = [
        { required: true, message: '请输入链接地址', trigger: 'blur' },
      ];
      this.articleInfo.abstract = '';
      this.articleInfo.content = '';
      this.articleInfo.attFiles = '';
    }
  }
  // 实例化编辑器
  private ready(editorInstance: any) {
    this.editorInstance = editorInstance;
  }
  private UploadFileFun(val: any) {
    this.articleInfo.attFiles = val;
  }

  // 保存+发布+修改
  private articleSubmit(flag: number) {
    // 2 保存（未发布）  1 提交（发布）
    this.loading = true;
    (this.articleFrom as any).validate((resBool: boolean) => {
      if (resBool) {
        if (this.id) {
          InstitutionArticleUp({
            id: this.id,
            author: '机构咨询',
            attFiles: this.articleInfo.attFiles,
            cmsType: '7',
            content: this.articleInfo.content,
            contentPure: this.articleInfo.abstract,
            source: this.articleInfo.source,
            url: this.articleInfo.url,
            title: this.articleInfo.title,
            status: flag,
          }).then((res) => {
            if (res.code === '000') {
              this.$message.success('修改成功');
              this.$router.go(-1);
            }
          });
        } else {
          InstitutionArticleAdd({
            author: '机构咨询',
            attFiles: this.articleInfo.attFiles,
            cmsType: '7',
            content: this.articleInfo.content,
            contentPure: this.articleInfo.abstract,
            source: this.articleInfo.source,
            url: this.articleInfo.url,
            title: this.articleInfo.title,
            status: flag,
          }).then((res) => {
            if (res.code === '000') {
              this.$message.success('提交成功');
              this.$router.go(-1);
            }
          });
        }
      } else {
        this.loading = false;
      }
    });
  }
}
